import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';
import { Link } from 'panamera-react-ui';

import css from './OpenInApp.APP_TARGET.scss';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import { fileNames } from 'Constants/imageUrls';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { SVG_TYPE } from 'Constants/images';
import Button from 'Components/Button/Button';
import withRouter from 'HOCs/withRouter';
import withCookies from 'HOCs/withCookies/withCookies';
import withConfig from 'HOCs/withConfig/withConfig';
import { setOpenInApp } from 'Actions/openInApp';
import { ICONS, ICON_COLORS } from 'Constants/items';
import { setItem as setSessionItem, getItem as getSessionStorageItem } from 'Helpers/sessionStorage';
import { OPEN_APP_NUDGE } from './constants';
import withTrack from 'HOCs/withTrack/withTrack';
import { OLX_APP_NUDGE_CLICKED, OLX_APP_NUDGE_DISMISSED, OLX_APP_NUDGE_DISPLAYED, OPEN_APP_PATH } from 'Constants/tracking';

let timeout = null;

export class OpenInApp extends React.Component {
    static propTypes = {
        setOpen: PropTypes.func,
        track: PropTypes.func,
        isAppNudgeOpen: PropTypes.bool,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        router: PropTypes.object.isRequired,
        nudgeTitle: PropTypes.string,
        targetURL: PropTypes.string,
        trackingEvents: PropTypes.shape({
            display: PropTypes.string,
            click: PropTypes.string,
            dismiss: PropTypes.string
        })
    }
    static defaultProps = {
        setOpen: () => { },
        nudgeTitle: 'openAppNudgeTitle',
        targetURL: OPEN_APP_PATH,
        trackingEvents: {
            display: OLX_APP_NUDGE_DISPLAYED,
            click: OLX_APP_NUDGE_CLICKED,
            dismiss: OLX_APP_NUDGE_DISMISSED
        }
    }

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleClickOpenApp = this.handleClickOpenApp.bind(this);
        this.staticAssetPath = props.config.get('staticAssets') ?? '';
    }

    componentDidMount() {
        const openAppNudge = getSessionStorageItem(OPEN_APP_NUDGE);

        if (openAppNudge === null || openAppNudge === 'true') {
            timeout = setTimeout(() => {
                setSessionItem(OPEN_APP_NUDGE, true);
                this.props.setOpen(true);
                this.props.track(this.props.trackingEvents.display);
            }, 0);
        }
    }

    componentWillUnmount() {
        clearTimeout(timeout);
    }
    handleClose = () => {
        this.props.track(this.props.trackingEvents.dismiss);
        setSessionItem(OPEN_APP_NUDGE, false);
        this.props.setOpen(false);
    }
    handleClickOpenApp = () => {
        this.props.track(this.props.trackingEvents.click);
        this.props.router.push(this.props.targetURL);
    }

    render() {
        return (
            <div className={ cx(css.openAppContainer, {
                [css.isVisible]: this.props.isAppNudgeOpen
            }) }>
                <div className={ css.leftContainer }>
                    <div
                        onClick={ this.handleClose }
                        data-aut-id="close-popup"
                        className={ css.closeBtn }
                        role="button"
                    >
                        <Icon
                            icon={ ICONS.CROSS }
                            size={ 12 }
                            color={ ICON_COLORS.WHITE_ICONS }
                        />
                    </div>
                    <div className={ css.olxLogo }>
                        <Link title="olx" to="/" data-aut-id="btnOlxLogo" className={ css.linkClass }>
                            <Icon
                                icon="olx"
                                size={ 26 }
                            />
                        </Link>
                    </div>
                    <div>
                        <h3 className={ css.infoText } data-aut-id="nudge-title">
                            <Translation id={ this.props.nudgeTitle } />
                        </h3>
                        <div className={ css.downloadMsg }>
                            <ImageWrapper
                                fileName={ fileNames.FIVE_STAR }
                                types={ SVG_TYPE }
                                width="60"
                                height="20"
                            />
                            <p>
                                <Translation id="downloadCountInfo" />
                            </p>
                        </div>
                    </div>
                </div>
                <Button
                    type="primaryBtn"
                    onClick={ this.handleClickOpenApp }
                    invert
                    data-aut-id="open-app-btn1"
                    role="link"
                    className={ css.openAppBtn }
                >
                    <Translation id="openAppText" />
                </Button>
            </div>);
    }
}

export const mapDispatchToProps = dispatch => {
    return {
        setOpen: data => dispatch(setOpenInApp(data))
    };
};

export const mapStateToProps = state => {
    return {
        isAppNudgeOpen: state.openInApp.isOpen
    };
};

export default compose(
    withRouter,
    withCookies,
    withTrack,
    withConfig,
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(OpenInApp);

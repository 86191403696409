/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import css from './BannerCarousel.scss';
import Slider from 'react-slick';
import withRouter from 'HOCs/withRouter';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { slugify } from 'Helpers/strings';
import trackingEvents from 'Constants/trackingEvents';

export const BannerCarousel = ({ widgetData, router, track, selectedLocation = {}, homeContentProjectCarousel }) => {
    const { data = {}, metadata, name } = widgetData;
    const { style } = metadata || {};
    const { items = [], sliderSettings = {}} = data;
    const commonSettings = {
        dots: true,
        infinite: false,
        swipeToSlide: true,
        focusOnSelect: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dotsClass: css.dotsClass
    };

    const settings = {
        ...commonSettings,
        ...sliderSettings
    };

    const handleClick = item => () => {
        item?.tracking.map(value => {
            track(value.event_name, value);
        });
        if (homeContentProjectCarousel && item?.is_real_estate_banner) {
            track(trackingEvents.homepagebannerclicked, {
                reason: 're_banner'
            });
            const projectsUrl = `projects/${slugify(selectedLocation?.name)}_g${selectedLocation?.id}`;

            router?.push(projectsUrl || '');
        }
        else if (window.open && item.target === '_blank') {
            window.open(item.action, '_blank')?.focus();
        }
        else {
            window.open(item.action || '');
        }
    };

    return (
        <div id={ name } className={ css.wrapper } style={ style } data-aut-id={ 'mobile_phones_banner_carousel_widget' }>
            <Slider { ...settings }>
                {items.map((item, index) => {
                    return (
                        <div data-aut-id={ 'click_cta' } key={ `banner_carousel_${index}` } className={ css.bannerItem } style={ item.itemStyle }>
                            {item.type === 'image' && item.image ? (
                                <div
                                    onClick={ handleClick(item) }
                                >
                                    <ImageWrapper
                                        fileName={ item?.image?.url }
                                        style={ item?.image?.style }
                                    />
                                </div>
                            ) : null}
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

BannerCarousel.propTypes = {
    widgetData: PropTypes.object.isRequired,
    data: PropTypes.array,
    metadata: PropTypes.object,
    track: PropTypes.func,
    name: PropTypes.string,
    staticAsseturl: PropTypes.string,
    router: PropTypes.object.isRequired,
    homeContentProjectCarousel: PropTypes.bool,
    selectedLocation: PropTypes.object
};

export default withRouter(BannerCarousel);

import PropTypes from 'prop-types';

/* global window */

import React from 'react';
import debounce from 'lodash/debounce';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';
import { compose } from 'redux';

import Button from 'Components/Button/Button';
import classNames from 'classnames';
import css from './FloatingPills.scss';
import SellButton from 'Components/SellButton/SellButton';
import { setItem as setSessionStorageItem, getItem as getSessionStorageItem } from 'Helpers/sessionStorage';
import { FLOATING_BTN_TYPES, HIDE_DISMISSIBLE_BANNER_KEY } from './constants';
import withConfig from 'HOCs/withConfig/withConfig';
import { connect } from 'react-redux';
import withSessionFeature from 'HOCs/withSessionFeature/withSessionFeature';
const DEBOUNCE_TIMER = 200;

export class FloatingPills extends React.Component {
    static propTypes = {
        buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
        dismissibleBanner: PropTypes.element,
        className: PropTypes.string,
        config: PropTypes.shape({
            get: PropTypes.func
        }).isRequired,
        isAppNudgeOpen: PropTypes.bool,
        sessionFeatures: PropTypes.arrayOf(PropTypes.string)
    };

    static defaultProps = {
        className: '',
        sessionFeatures: []
    };

    constructor(props) {
        super(props);

        this.state = {
            sticked: false,
            stickHeight: '',
            hideDismissibleBanner: false
        };
        this.stickHeight = '';

        this.debounceScroll = debounce(this.handleScroll, DEBOUNCE_TIMER);
        this.debounceResize = debounce(this.handleResize, DEBOUNCE_TIMER);
        this.theme = props.config.get('theme', 'id');
    }

    componentDidMount() {
        this.lastElm = document.getElementById('related_searches_mobile') || document.getElementById('footer');
        window.addEventListener('scroll', this.debounceScroll);
        window.addEventListener('resize', this.debounceResize);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.debounceScroll);
        window.removeEventListener('resize', this.debounceResize);
    }

    computeStickHeight = () => {
        if (this.lastElm) {
            const ypos = this.lastElm.getBoundingClientRect().top - document.body.getBoundingClientRect().top;

            this.stickHeight = ypos - window.innerHeight;
        }
        else {
            this.lastElm = document.getElementById('related_searches_mobile') || document.getElementById('footer');
        }
    }

    handleResize = () => {
        this.computeStickHeight();
        this.handleScroll();
    }

    handleScroll = () => {
        this.computeStickHeight();
        if (this.state.sticked) {
            if (window.scrollY < this.stickHeight) {
                this.setState({ sticked: false });
            }
        }
        else if (!this.state.sticked && window.scrollY >= this.stickHeight && this.stickHeight) {
            this.setState({ sticked: true });
        }
    }

    getButton(el) {
        switch (el.type) {
            case FLOATING_BTN_TYPES.SELL: {
                return (
                    <SellButton
                        href={ el.href }
                        onClick={ el.onClick }
                        label={ el.text }
                        theme={ this.theme }
                    />
                );
            }
            default: {
                return (
                    <Button
                        type="primaryBtn"
                        isRounded
                        icon={ el.icon }
                        href={ el.href }
                        className={ css.floatingBtn }
                        onClick={ el.onClick }
                    >
                        {el.text}
                    </Button>
                );
            }
        }
    }

    hideDismissibleBanner = () => {
        setSessionStorageItem(HIDE_DISMISSIBLE_BANNER_KEY, true);
        this.setState({ hideDismissibleBanner: true });
    }

    render() {
        const ulClassName = classNames(
            css.floatingList,
            this.props.className,
            {
                [css.onBottom]: this.state.sticked,
                [css.pushed]: !this.props.isAppNudgeOpen && !this.state.sticked
            },
            this.props.sessionFeatures?.includes('olxin-4314') && css.floatingListNew
        );

        const callToActions = this.props.buttons.map((el, key) => (
            <li key={ key } className={ css.floatingElement }>
                {this.getButton(el)}
            </li>
        ));

        const dismissibleBanner = !getSessionStorageItem(HIDE_DISMISSIBLE_BANNER_KEY) && this.props.dismissibleBanner && (
            <div className={ css.dismissibleBanner }>
                <NewIconButton
                    icon="cross"
                    onClick={ this.hideDismissibleBanner }
                    className={ css.closeBtn }
                />
                {this.props.dismissibleBanner}
            </div>
        );

        return (
            <ul id="floating-sell-btn" ref={ floatingList => (this.floatingList = floatingList) } className={ ulClassName }>
                { callToActions }
                { dismissibleBanner }
            </ul>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAppNudgeOpen: state.openInApp.isOpen
    };
};

export default compose(withConfig, withSessionFeature, connect(mapStateToProps, null))(FloatingPills);

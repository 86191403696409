import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withConfig from 'HOCs/withConfig/withConfig';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchWidgetData, widgetConfigUrl } from 'Actions/widgetConfig';
import withRouter from 'HOCs/withRouter';
import withTrack from 'HOCs/withTrack/withTrack';
import withSessionLong from 'HOCs/withSessionLong/withSessionLong';
import BannerCarousel from './BannerCarousel';
import trackingEvents from 'Constants/trackingEvents';
export const ListingCarousel = ({
    fetchBannerConfig,
    widgetData,
    widgetToFetch,
    track,
    homeContentProjectCarousel,
    selectedLocation
}) => {
    useEffect(() => {
        fetchBannerConfig(widgetToFetch);
        track(trackingEvents.hometopbannershown, {
            reason: 're_banner'
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (widgetData?.data) {
        return (
            <BannerCarousel
                widgetData={ widgetData?.data }
                track={ track }
                homeContentProjectCarousel={ homeContentProjectCarousel }
                selectedLocation={ selectedLocation }
            />
        );
    }
    return null;
};

ListingCarousel.propTypes = {
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    fetchBannerConfig: PropTypes.func,
    widgetData: PropTypes.object,
    location: PropTypes.shape({
        query: PropTypes.object,
        pathname: PropTypes.string
    }),
    widgetToFetch: PropTypes.string,
    track: PropTypes.func,
    homeContentProjectCarousel: PropTypes.bool,
    selectedLocation: PropTypes.object
};

export const mapStateToProps = (state, ownProps) => {
    return {
        widgetData: state.widgetConfig[widgetConfigUrl(ownProps.widgetToFetch)],
        selectedLocation: state.locations.selectedLocation
    };
};

export const mapDispatchToProps = dispatch => ({
    fetchBannerConfig: widgetUrlName => dispatch(fetchWidgetData(widgetUrlName))
});

export default compose(
    withRouter,
    withTrack,
    withConfig,
    withSessionLong,
    connect(mapStateToProps, mapDispatchToProps),
    React.memo
)(ListingCarousel);
